<template>
    <div class="dashboard-card has-inner affiliate-performance">
        <div class="inner">
            <div class="dashboard-card-header">
                <h4 class="m-0">Sub-Affiliate Performance</h4>
            </div>
            <div class="dashboard-card-body">
                <ul class="card-list">
                    <li v-for="user in affiliateItems" class="card-row">
                        <span class="card-affiliate-name">{{user.company_name}}<br><span>{{ user.count }} Sale{{ user.count === 1? '' : 's' }}</span></span>
                         <span class="card-affiliate-total">{{ user.total | formatMoney }}</span>
                    </li>
                </ul>
                <div class="card-loader" v-if="loading" v-cloak><i class="far fa-spinner-third fa-spin"></i></div>
            </div>
            <div class="dashboard-card-footer">
                <a href="/dashboard-admin/sales-performance" v-if="roleId === 1" class="btn btn-secondary btn-export" v-cloak>View All</a>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
let currency = require('currency.js');

var appElement = document.getElementById('app');
var apiToken = appElement.getAttribute('data-api-token');
var roleId = parseInt(appElement.getAttribute('data-role-id'));

const HTTP = axios.create(axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : app.csrfToken,
    'Authorization' : 'Bearer '+ apiToken,
    'Content-Type': 'multipart/form-data'
});

module.exports = {
    props: [ 'startDate', 'endDate' ],
    data: function() {
        return {
            loading: true,
            length: 5,
            showAll: false,
            roleId: 0,
            affiliates: [],
        };
    },
    computed: {
        affiliateItems(){
            let length = this.showAll === true? this.affiliates.length : this.length;
            return this.affiliates.slice(0, length);
        }
    },
    filters: {
        formatMoney(value){
            if (!value) return '';
            return currency(value).format();
        },
    },
    methods: {
        getData(){
            this.loading = true;
            const url = '/api/affiliateperformance?start_date='+this.startDate+'&end_date='+this.endDate;

            HTTP.get(url)
            .then(response => {
                this.affiliates = response.data;
                this.loading = false;
            })
            .catch(e => {
                console.log('Affiliate performance error', e);
                this.loading = false;
            });
        },
        toggleList: function(){
            this.showAll = !this.showAll;
        }
    },
    created(){
        this.roleId = roleId;
    },
    mounted(){
        this.getData();
    }
};
</script>

<style scoped>
.affiliate-performance{
    margin-bottom: 32px;
}
.card-affiliate-total{
    margin-left: auto;
    color: #7d9eb5;
}
.card-affiliate-name{
    font-size: .875rem;
    font-weight: 600;
}

.card-affiliate-name span{
    font-weight: normal;
}
</style>
