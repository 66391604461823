require("./bootstrap");
let $ = require("jquery");
import Vue from "vue";
import axios from "axios";
let currency = require("currency.js");
import Cookies from "js-cookie";
import flatpickr from "flatpickr";
import DataTable from "./components/data-table";
import TopCards from "./components/top-cards";
import RepCommissions from "./components/rep-commissions";
import AffiliatePerformance from "./components/affiliate-performance";
import CardStatsAdmin from "./components/card-stats-admin";
import LineChart from "./components/charts/line-chart";
import Popper from "popper.js";
import moment from "moment";
import LastPayment from "./components/last-payment";

window.Cookies = Cookies;

var appElement = document.getElementById("app");
var csrfToken = document.querySelector('meta[name="csrf-token"]').content;
var apiToken = appElement.getAttribute("data-api-token");
var userId = parseInt(appElement.getAttribute("data-user-id"));
var roleId = parseInt(appElement.getAttribute("data-role-id"));
var cardElement = document.getElementById("cards-table");
var affiliateId;

if (cardElement) {
    affiliateId = parseInt(cardElement.getAttribute("data-affiliate-id"));
    affiliateId = affiliateId ? affiliateId : null;
}

const HTTP = axios.create(
    (axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-TOKEN": app.csrfToken,
        Authorization: "Bearer " + apiToken,
        "Content-Type": "multipart/form-data",
    })
);

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

// const numFor = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2,});

window.parseReportStatus = function (status) {
    switch (status) {
        case "DRAFT":
            status = '<span class="report-status draft">Draft</span>';
            break;
        case "PENDING":
            status = '<span class="report-status pending">Pending</span>';
            break;
        case "SENT":
            status = '<span class="report-status sent">Sent</span>';
            break;
        case "PAID":
            status = '<span class="report-status paid">Paid</span>';
            break;
    }

    return status;
};

window.app = new Vue({
    el: "#app",
    components: {
        DataTable,
        TopCards,
        AffiliatePerformance,
        LineChart,
        CardStatsAdmin,
        RepCommissions,
        LastPayment,
    },
    data: () => ({
        errors: [],
        saving: false,
        loading: false,
        searchKeyupTime: 500,
        cards: [],
        clicks: [],
        sales: [],
        monthlySales: [],
        activities: [],
        affiliates: [],
        page: 1,
        activityPage: 1,
        length: 20,
        limit: 3,
        lastPage: 0,
        currentPage: 0,
        total: 0,
        activityTotal: 0,
        activityLastPage: 0,
        startDate: "",
        endDate: "",
        sortUpClass: "fa-sort-up",
        sortDownClass: "fa-sort-down",
        searchQuery: "",
        tableConfig: {
            fetchUrl: "/api/salespersons",
            columns: [
                {
                    title: "Name",
                    name: "name",
                    dir: "asc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            '<a href="/sales-persons/' +
                            value.id +
                            '">' +
                            value.name +
                            "</a>"
                        );
                    },
                },
                {
                    title: "Email",
                    name: "email",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                },
                {
                    title: "Actions",
                    sortable: false,
                    width: "120px",
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        return (
                            '<a class="btn btn-light" href="/sales-persons/' +
                            value.id +
                            '"><i class="far fa-pencil"></i></a> <a href="/impersonate/sub-affiliate/' +
                            value.id +
                            '" class="btn btn-light"><i class="far fa-user-secret"></i></a>'
                        );
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: false,
            orderBy: "name",
            dir: "asc",
        },
        messagesTableConfig: {
            fetchUrl: "/api/messages",
            columns: [
                {
                    title: "Title",
                    name: "title",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let html = "";
                        html +=
                            '<a href="/messages/' +
                            value.id +
                            '"><span class="subject-truncated">' +
                            value.title +
                            "</span></a>";

                        if (value.attachments.length > 0) {
                            html +=
                                '&nbsp;&nbsp;<i class="fa fa-paperclip"></i>';
                        }
                        if (value.message_level == 'draft') {
                            html +=
                                '&nbsp;&nbsp;<em>Draft<em/>';
                        }
                        return html;
                    },
                },
                {
                    title: "Subject",
                    name: "subject",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            '<span class="subject-truncated">' +
                            value.subject.substr(0, 50) +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Type",
                    name: "message_type",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let type = "";
                        switch (value.message_type) {
                            case "1":
                                type =
                                    '<span class="report-status paid">Offer Update</span>';
                                break;
                            case "2":
                                type =
                                    '<span class="report-status pending">Compliance</span>';
                                break;
                            case "3":
                                type =
                                    '<span class="report-status pending">Announcements</span>';
                                break;
                            case "4":
                                type =
                                    '<span class="report-status sent">General</span>';
                                break;
                        }

                        return type;
                    },
                },
                {
                    title: "Date",
                    name: "m_date",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return moment(value.m_date).format("MMM D, yyyy");
                    },
                },
                {
                    title: "Status",
                    name: "message_level",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                },
                {
                    title: "Actions",
                    sortable: false,
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        let html = "",
                            createdBy = value.created_by == userId;
                        // Only Admins, Managers, and Staff are able to edit the message
                        if (
                            (roleId == 1 && createdBy) ||
                            (roleId == 6 && createdBy) ||
                            (roleId == 4 && createdBy)
                        ) {
                            html +=
                                '<a class="btn btn-light" href="/messages/' +
                                value.id +
                                '"><i class="far fa-pencil"></i></a>';
                        }
                        html +=
                            '<a class="btn btn-light" href="/messages/show/' +
                            value.id +
                            '"><i class="far fa-angle-right"></i></a>';
                        return html;
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: false,
            orderBy: "m_date",
            dir: "desc",
        },
        paymentsTableConfig: {
            fetchUrl: "/api/payments",
            columns: [
                {
                    title: "Affiliate Name",
                    name: "name",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let html = "";
                        if (value.parent_id === 0) {
                            html +=
                                '<a class="" href="/sub-affiliates/activity/' +
                                value.user_id +
                                '">' +
                                value.name +
                                "</a>";
                        } else {
                            html +=
                                '<a class="" href="/sub-affiliates/' +
                                value.parent_id +
                                '/contacts">' +
                                value.name +
                                "</a>";
                        }

                        if (value.company_name) {
                            html += `<br/><span class="company-name">${value.company_name}</span>`;
                        }

                        return html;
                    },
                },
                {
                    title: "Payment Amount",
                    name: "payment_amount",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        if (value.is_override_payment == true) {
                            return (
                                "<span>" +
                                currency(value.override_amount).format() +
                                "</span>"
                            );
                        } else {
                            return (
                                "<span>" +
                                currency(value.payment_amount).format() +
                                "</span>"
                            );
                        }
                    },
                },
                {
                    title: "Invoice #",
                    name: "invoice_no",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        let fDate =
                            months[value.r_month - 1] + " " + value.r_year;
                        let path = "";
                        if (roleId == 1) {
                            return (
                                '<a id="paymentStatus" class="payment-detail" href="' +
                                path +
                                "/sales-reports/" +
                                value.invoice_no +
                                '" data-value="' +
                                value.invoice_no +
                                '">' +
                                fDate +
                                " Report #" +
                                value.invoice_no +
                                " </a>"
                            );
                        } else {
                            return (
                                "<span>" +
                                fDate +
                                " Report #" +
                                value.invoice_no +
                                " </span>"
                            );
                        }
                    },
                },
                {
                    title: "Description",
                    name: "description",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        return (
                            '<span class="subject-truncated">' +
                            value.description.substr(0, 30) +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Payment Date",
                    name: "payment_date",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return moment(value.payment_date).format("MM/DD/yyyy");
                    },
                },
                {
                    title: "Payment Type",
                    name: "payment_type",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return "<span>" + value.payment_type + "</span>";
                    },
                },
                {
                    title: "Actions",
                    sortable: false,
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        let html = "";
                        // Admin only able to edit the message
                        if (roleId == 1) {
                            html +=
                                '<a class="btn btn-light payment-detail" href="javascript:void(0);" data-value="' +
                                value.id +
                                '"> <i class="far fa-pencil"></i> </a>';
                        }
                        html +=
                            '<a class="btn btn-light" href="/payments/show/' +
                            value.id +
                            '"><i class="far fa-angle-right"></i></a>';
                        return html;
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: false,
            orderBy: "payment_date",
            dir: "desc",
        },
        affiliatePaymentsTableConfig: {
            fetchUrl: "/api/user-payments",
            columns: [
                {
                    title: "Payment Amount",
                    name: "payment_amount",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        if (value.is_override_payment == true) {
                            return (
                                "<span>" +
                                currency(value.override_amount).format() +
                                "</span>"
                            );
                        } else {
                            return (
                                "<span>" +
                                currency(value.payment_amount).format() +
                                "</span>"
                            );
                        }
                    },
                },
                {
                    title: "Invoice #",
                    name: "invoice_no",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        let fDate =
                            months[value.r_month - 1] + " " + value.r_year;
                        let path = "";
                        if (roleId == 3) {
                            return (
                                '<a id="paymentStatus" class="payment-detail" href="' +
                                path +
                                "/sales-reports/" +
                                value.invoice_no +
                                '" data-value="' +
                                value.invoice_no +
                                '">' +
                                fDate +
                                " Report #" +
                                value.invoice_no +
                                " </a>"
                            );
                        } else {
                            return (
                                "<span>" +
                                fDate +
                                " Report #" +
                                value.invoice_no +
                                " </span>"
                            );
                        }
                    },
                },
                {
                    title: "Description",
                    name: "description",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        return (
                            '<span class="subject-truncated">' +
                            value.description.substr(0, 30) +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Payment Date",
                    name: "payment_date",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return moment(value.payment_date).format("MM/DD/yyyy");
                    },
                },
                {
                    title: "Payment Type",
                    name: "payment_type",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return "<span>" + value.payment_type + "</span>";
                    },
                },
                {
                    title: "Actions",
                    sortable: false,
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        let html = "";
                        // Admin only able to edit the message
                        if (roleId == 1) {
                            html +=
                                '<a class="btn btn-light payment-detail" href="javascript:void(0)" data-value="' +
                                value.id +
                                '"> <i class="far fa-pencil"></i> </a>';
                        }
                        html +=
                            '<a class="btn btn-light" href="/payments/show/' +
                            value.id +
                            '"><i class="far fa-angle-right"></i></a>';
                        return html;
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: false,
            orderBy: "payment_date",
            dir: "desc",
        },
        userAffiliateMessagesTableConfig: {
            fetchUrl: "/api/user-based-messages",
            columns: [
                {
                    title: "Name",
                    name: "subject",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let html = "";

                        html =
                            '<span class="subject-truncated"><a href="/messages/show/' +
                            value.id +
                            '">' +
                            value.subject +
                            "</a></span>";

                        if (value.attachments.length > 0) {
                            html +=
                                '&nbsp;&nbsp;<i class="fa fa-paperclip"></i>';
                        }

                        if (value.pivot.read != "1") {
                            html +=
                                '&nbsp;<span class="message-notification" style="float: none;"> New </span>';
                        }

                        return html;
                    },
                },
                {
                    title: "Type",
                    name: "message_type",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let type = "";
                        switch (value.message_type) {
                            case "1":
                                type =
                                    '<span class="report-status paid">Offer Update</span>';
                                break;
                            case "2":
                                type =
                                    '<span class="report-status pending">Compliance</span>';
                                break;
                            case "3":
                                type =
                                    '<span class="report-status pending">Announcements</span>';
                                break;
                            case "4":
                                type =
                                    '<span class="report-status sent">General</span>';
                                break;
                        }

                        return type;
                    },
                },
                {
                    title: "Date",
                    name: "m_date",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return moment(value.updated_at).format("MMM D, yyyy");
                    },
                },
                {
                    title: "Actions",
                    sortable: false,
                    type: "actions",
                    width: "",
                    dataAlign: "right",
                    template: (value) => {
                        let html = "";
                        // Admin only able to edit the message
                        if (roleId == 1) {
                            html +=
                                '<a class="btn btn-light" href="/messages/' +
                                value.id +
                                '"><i class="far fa-pencil"></i></a>';
                        }
                        html +=
                            '<a class="btn btn-light" href="/messages/show/' +
                            value.id +
                            '"><i class="far fa-angle-right"></i></a>';

                        return html;
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: false,
            orderBy: "m_date",
            dir: "desc",
        },
        userMessagesTableConfig: {
            fetchUrl: "/api/user-based-messages",
            columns: [
                {
                    title: "Name",
                    name: "title",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            '<span class="company-name">' +
                            value.title +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Subject",
                    name: "subject",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            '<span class="subject-truncated">' +
                            value.subject +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Type",
                    name: "message_type",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let type = "";
                        switch (value.message_type) {
                            case "1":
                                type =
                                    '<span class="report-status paid">Offer Update</span>';
                                break;
                            case "2":
                                type =
                                    '<span class="report-status pending">Compliance</span>';
                                break;
                            case "3":
                                type =
                                    '<span class="report-status pending">Announcements</span>';
                                break;
                            case "4":
                                type =
                                    '<span class="report-status sent">General</span>';
                                break;
                        }

                        return type;
                    },
                },
                {
                    title: "Date",
                    name: "m_date",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return moment(value.updated_at).format("MMM D, yyyy");
                    },
                },
                {
                    title: "Status",
                    name: "message_level",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                },
                {
                    title: "Actions",
                    sortable: false,
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        let html = "";
                        // Admin only able to edit the message
                        if (roleId == 1) {
                            html +=
                                '<a class="btn btn-light" href="/messages/' +
                                value.id +
                                '"><i class="far fa-pencil"></i></a>';
                        }
                        html +=
                            '<a class="btn btn-light" href="/messages/show/' +
                            value.id +
                            '"><i class="far fa-angle-right"></i></a>';
                        if (value.pivot.read != "1") {
                            html +=
                                '<a href="javascript:void(0)" class="new-button"><span class="message-notification"> New </span></a>';
                        }
                        return html;
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: false,
            orderBy: "m_date",
            dir: "desc",
        },
        salesReportsTableConfig: {
            fetchUrl: "/api/salesreports",
            columns: [
                {
                    title: "Customer",
                    name: "name",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            value.name +
                            '<br><span class="company-name">' +
                            (value.parent_affiliate
                                ? value.parent_affiliate.company_name
                                : value.company_name) +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Description",
                    name: "report_month",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        let fDate =
                            months[value.r_month - 1] + " " + value.r_year;

                        //let path = roleId === 3? '' : '/dashbaord-admin';
                        let path = "";

                        return (
                            '<a href="' +
                            path +
                            "/sales-reports/" +
                            value.id +
                            '">' +
                            fDate +
                            " Sales Report</a>"
                        );
                    },
                },
                {
                    title: "Total",
                    name: "total",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "total",
                    template: (value) => {
                        return currency(value.total).format();
                    },
                },
                {
                    title: "Status",
                    name: "status",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            "<strong>" +
                            window.parseReportStatus(value.status) +
                            "</strong>"
                        );
                    },
                },
                {
                    title: "Actions",
                    sortable: false,
                    width: "220",
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        let html =
                            '<a class="btn btn-light" href="/sales-reports/' +
                            value.id +
                            '"><i class="far fa-eye"></i></a>';
                        if (roleId === 1) {
                            html +=
                                '<a class="btn btn-light sale-report" href="javascript:void(0)" data-value="' +
                                value.id +
                                '"> $ </a>';
                            html +=
                                ' &nbsp; <div class="dropdown"><a class="btn btn-light dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href=""><i class="far fa-print"></i></a><div class="dropdown-menu dropdown-menu-right"><a class="dropdown-item" href="/sales-reports/' +
                                value.id +
                                '/print">Print MileValue Commissions</a><a class="dropdown-item" href="/sales-reports/' +
                                value.id +
                                '/print?for_affiliate=1">Print Affiliate Commissions</a></div></div>';

                            html +=
                                '<form style="display: inline-block" action="/dashboard-admin/sales-reports/' +
                                value.id +
                                '" method="post"><input type="hidden" name="_token" value="' +
                                csrfToken +
                                '"><input type="hidden" name="_method" value="delete"><button type="submit" class="btn btn-light confirm-delete""><i class="far fa-times" style="color: red"></i></button></form>';
                        }

                        if (roleId === 3) {
                            html +=
                                '<a class="btn btn-light" role="button" href="/sales-reports/' +
                                value.id +
                                '/print"><i class="far fa-print"></i></a>';
                        }

                        return html;
                    },
                },
            ],
            extraParams: {
                month: { value: "" },
                year: { value: "" },
                status: { value: "" },
            },
            showTotal: true,
            orderBy: "report_month",
            dir: "desc",
        },
        monthlyReportsTableConfig: {
            fetchUrl: "/api/monthlyreports",
            columns: [
                {
                    title: "Customer",
                    name: "name",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            value.name +
                            '<br><span class="company-name">' +
                            value.company_name +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Paypal",
                    name: "paypal_email",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let email = "";
                        if (value.paypal_email !== null) {
                            email = value.paypal_email;
                        }
                        return email;
                    },
                },
                {
                    title: "Description",
                    name: "report_month",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        let fDate =
                            months[value.r_month - 1] + " " + value.r_year;

                        let path = "";

                        return (
                            '<a href="' +
                            path +
                            "/sales-reports/" +
                            value.id +
                            '">' +
                            fDate +
                            " Referral Report</a>"
                        );
                    },
                },
                {
                    title: "Total",
                    name: "total",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "total",
                    template: (value) => {
                        return currency(value.total).format();
                    },
                },
                {
                    title: "Status",
                    name: "status",
                    sortable: false,
                    width: "140",
                    type: "string",
                    dataAlign: "center",
                    template: (value) => {
                        let html = "";
                        html +=
                            '<select class="toggle-status-select" id="paymentStatus">';
                        if (value.status === "ADJUSTED") {
                            html +=
                                '<option value="' +
                                value.id +
                                ':ADJUSTED" ' +
                                (value.status === "ADJUSTED"
                                    ? 'selected="selected"'
                                    : "") +
                                ">ADJUSTED</option>";
                        }
                        html +=
                            '<option value="' +
                            value.id +
                            ':DRAFT" ' +
                            (value.status === "DRAFT"
                                ? 'selected="selected"'
                                : "") +
                            ">DRAFT</option>";
                        html +=
                            '<option value="' +
                            value.id +
                            ':PENDING" ' +
                            (value.status === "PENDING"
                                ? 'selected="selected"'
                                : "") +
                            ">PENDING</option>";
                        html +=
                            '<option value="' +
                            value.id +
                            ':SENT" ' +
                            (value.status === "SENT"
                                ? 'selected="selected"'
                                : "") +
                            ">SENT</option>";
                        html +=
                            '<option value="' +
                            value.id +
                            ':PAID" ' +
                            (value.status === "PAID"
                                ? 'selected="selected"'
                                : "") +
                            ">PAID</option>";
                        html += "</select>";
                        return html;
                    },
                },
                {
                    title: "Actions",
                    sortable: false,
                    width: "100",
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        return (
                            '<a class="btn btn-light sale-report" href="javascript:void(0)" data-value="' +
                            value.id +
                            '"> $ </a>'
                        );
                    },
                },
            ],
            extraParams: {
                month: { value: "" },
                year: { value: "" },
                status: { value: "" },
            },
            showTotal: false,
            orderBy: "report_month",
            dir: "desc",
        },
        monthlyRepsReportsTableConfig: {
            fetchUrl: "/api/monthlyreports-reps",
            columns: [
                {
                    title: "Customer",
                    name: "name",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            value.name +
                            '<br><span class="company-name">' +
                            value.company_name +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Paypal",
                    name: "paypal_email",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        let email = "";
                        if (value.paypal_email !== null) {
                            email = value.paypal_email;
                        }
                        return email;
                    },
                },
                {
                    title: "Description",
                    name: "report_month",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        let fDate =
                            months[value.r_month - 1] + " " + value.r_year;

                        let path = "";

                        return (
                            '<a href="' +
                            path +
                            "/sales-reps-reports/" +
                            value.id +
                            '">' +
                            fDate +
                            " Referral Report</a>"
                        );
                    },
                },
                {
                    title: "Total",
                    name: "total",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "total",
                    template: (value) => {
                        return currency(value.total).format();
                    },
                },
                {
                    title: "Status",
                    name: "status",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                },
                {
                    title: "Actions",
                    sortable: false,
                    width: "100",
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        return (
                            '<a class="btn btn-light sale-report" href="javascript:void(0)" data-value="' +
                            value.id +
                            '"> $ </a>'
                        );
                    },
                },
            ],
            extraParams: {
                month: { value: "" },
                year: { value: "" },
                status: { value: "" },
            },
            showTotal: false,
            orderBy: "report_month",
            dir: "desc",
        },
        salesReportsRepsTableConfig: {
            fetchUrl: "/api/salesreports",
            columns: [
                {
                    title: "Customer",
                    name: "name",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            value.name +
                            '<br><span class="company-name">' +
                            value.company_name +
                            "</span>"
                        );
                    },
                },
                {
                    title: "Description",
                    name: "report_month",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ];
                        let fDate =
                            months[value.r_month - 1] + " " + value.r_year;

                        return fDate + " Report";
                    },
                },
                {
                    title: "Affiliate Total",
                    name: "total",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "total",
                    template: (value) => {
                        return currency(value.total).format();
                    },
                },
                {
                    title: "Rep Total",
                    name: "rep_total",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "total",
                    template: (value) => {
                        return currency(value.rep_total).format();
                    },
                },
                {
                    title: "Status",
                    name: "status",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            "<strong>" +
                            window.parseReportStatus(value.status) +
                            "</strong>"
                        );
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: true,
            orderBy: "report_month",
            dir: "desc",
        },
        performanceTableConfig: {
            fetchUrl: "/api/salesperformance",
            columns: [
                {
                    title: "Sub-Affiliate",
                    name: "company_name",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                },
                {
                    title: "Card Sales",
                    name: "card_sales",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return value.card_sales;
                    },
                },
                {
                    title: "Revenue Share",
                    name: "affiliate_revenue_share",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "total",
                    template: (value) => {
                        return (
                            value.affiliate_revenue_share +
                            " / " +
                            value.mv_revenue_share
                        );
                    },
                },
                {
                    title: "Gross Commissions",
                    name: "gross_commission",
                    dir: "desc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return currency(value.gross_commission).format();
                    },
                },
                {
                    title: "Sub-Affiliate Commissions",
                    name: "affiliate_commission",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return currency(value.affiliate_commission).format();
                    },
                },
                {
                    title: "MileValue Commission",
                    name: "mv_commission",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return currency(value.mv_commission).format();
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
            },
            showTotal: false,
            orderBy: "gross_commission",
            dir: "desc",
        },
        cardsTableSettings: {
            orderBy: "short_desc",
            sortDir: "asc",
            filters: {
                force_show: true,
            },
            columns: {
                short_desc: { name: "Card Name", dir: "asc" },
                commission: { name: "Commssion", dir: "asc" },
                status: { name: "Status", dir: "asc" },
            },
        },
        clicksTableSettings: {
            orderBy: "clicked_at",
            sortDir: "desc",
            columns: {
                clicked_at: { name: "Click Date", dir: "asc" },
                product_name: { name: "Product Name", dir: "asc" },
                trackid: { name: "Tracking ID", dir: "asc" },
                c19: { name: "Affiliate", dir: "asc" },
            },
        },
        salesTableSettings: {
            orderBy: "sale_process_date",
            sortDir: "desc",
            columns: {
                click_date: { name: "Click Date", dir: "asc" },
                sale_process_date: { name: "Sale Date", dir: "desc" },
                customer_id: { name: "Sub-Affiliate", dir: "asc" },
                sale_commission: { name: "Commssion", dir: "asc" },
                referral_card_title: { name: "Product Name", dir: "asc" },
            },
        },
        monthlySalesTableSettings: {
            orderBy: "report_month",
            sortDir: "desc",
            columns: {
                sale_process_date: { name: "Date", dir: "desc" },
                report_month: { name: "Description", dir: "asc" },
                status: { name: "Status", dir: "asc" },
                total: { name: "Total", dir: "asc" },
            },
        },
        affiliatesTableSettings: {
            orderBy: "customer_id",
            sortDir: "asc",
            filters: {
                status: "A",
            },
            columns: {
                name: { name: "name", dir: "asc" },
                email: { name: "email", dir: "asc" },
                customer_id: { name: "customer_id", dir: "asc" },
                status: { name: "status", dir: "asc" },
                total: { name: "Total", dir: "asc" },
            },
        },
        tableUserConfig: {
            fetchUrl: "/api/users",
            columns: [
                {
                    title: "Name",
                    name: "name",
                    dir: "asc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            '<a href="/dashboard-admin/users/' +
                            value.id +
                            '">' +
                            value.name +
                            "</a>"
                        );
                    },
                },
                {
                    title: "Email",
                    name: "email",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                },
                {
                    title: "Role",
                    name: "role_name",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                },
                {
                    title: "Actions",
                    sortable: false,
                    width: "120px",
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        return (
                            '<a class="btn btn-light" href="/dashboard-admin/users/' +
                            value.id +
                            '"><i class="far fa-pencil"></i></a> <a href="/impersonate/sub-affiliate/' +
                            value.id +
                            '" class="btn btn-light"><i class="far fa-user-secret"></i></a>'
                        );
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
                role_id: { value: "" },
            },
            showTotal: false,
            orderBy: "name",
            dir: "asc",
        },
        managerTableUserConfig: {
            fetchUrl: "/api/users",
            columns: [
                {
                    title: "Name",
                    name: "name",
                    dir: "asc",
                    sortable: true,
                    width: "",
                    type: "string",
                    template: (value) => {
                        return (
                            '<a href="/users/' +
                            value.id +
                            '">' +
                            value.name +
                            "</a>"
                        );
                    },
                },
                {
                    title: "Email",
                    name: "email",
                    dir: "",
                    sortable: true,
                    width: "",
                    type: "string",
                },
                {
                    title: "Role",
                    name: "role_name",
                    dir: "",
                    sortable: false,
                    width: "",
                    type: "string",
                },
                {
                    title: "Actions",
                    sortable: false,
                    width: "120px",
                    type: "actions",
                    dataAlign: "center",
                    template: (value) => {
                        return (
                            '<a class="btn btn-light" href="/users/' +
                            value.id +
                            '"><i class="far fa-pencil"></i></a> <a href="/impersonate/sub-affiliate/' +
                            value.id +
                            '" class="btn btn-light"><i class="far fa-user-secret"></i></a>'
                        );
                    },
                },
            ],
            extraParams: {
                start_date: { value: "" },
                end_date: { value: "" },
                role_id: { value: "" },
            },
            showTotal: false,
            orderBy: "name",
            dir: "asc",
        },
        tabName: "",
        lifeTimeRevenue: 0,
        lifeTimeCommission: 0,
        lifeTimeMvCommission: 0,
        lifeTimeCardSales: 0,
        referralCount: 0,
        clickCount: 0,
        customerId: "",
        reportMonth: 0,
        reportYear: 0,
        cardSales: 0,
        creditCardClicks: 0,
        earningsPerClick: 0,
        clickThruRate: 0,
        statsRevenue: 0,
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        toastMessage: "",
        reportSending: false,
        tableTotal: 0.0,
        salesReportChartLoaded: false,
        salesReportChartData: null,
        trafficReportChartLoaded: false,
        trafficReportChartData: null,
        trafficReportChartOptions: {},
        salesReportChartOptions: {},
    }),
    filters: {
        formatMoney(value) {
            if (!value) return "";
            return currency(value).format();
        },
        formatThousand(value) {
            if (!value) return "";
            return value.toLocaleString();
        },
        formatReportDesc(value) {
            let fDate = months[value.r_month - 1] + " " + value.r_year;
            return fDate;
        },
    },
    computed: {
        years() {
            let year = new Date().getFullYear();
            let oldYear = year - 3;
            return Array.from(
                { length: year - oldYear },
                (value, index) => year--
            );
        },
        pageRange() {
            if (this.limit === -1) {
                return 0;
            }
            if (this.limit === 0) {
                return this.lastPage;
            }
            var current = this.currentPage;
            var last = this.lastPage;
            var delta = this.limit;
            var left = current - delta;
            var right = current + delta + 1;
            var range = [];
            var pages = [];
            var l;
            for (var i = 1; i <= last; i++) {
                if (i === 1 || i === last || (i >= left && i < right)) {
                    range.push(i);
                }
            }
            range.forEach(function (i) {
                if (l) {
                    if (i - l === 2) {
                        pages.push(l + 1);
                    } else if (i - l !== 1) {
                        pages.push("...");
                    }
                }
                pages.push(i);
                l = i;
            });
            return pages;
        },
    },
    methods: {
        getCards() {
            this.showLoading();
            HTTP.get(
                "/api/cards?page=" +
                this.page +
                "&order_by=" +
                this.cardsTableSettings.orderBy +
                "&sort_dir=" +
                this.cardsTableSettings.sortDir +
                "&length=" +
                this.length +
                "&search_query=" +
                this.searchQuery +
                "&filter_name=force_show" +
                "&filter_value=" +
                this.cardsTableSettings.filters.force_show +
                "&affiliate_id=" +
                affiliateId
            )
                .then((response) => {
                    this.cards = response.data.cards.data;
                    this.total = response.data.cards.total;
                    this.lastPage = response.data.cards.last_page;
                    this.hideLoading();
                })
                .catch((e) => {
                    //console.log('sort error');
                });
        },
        runApiImport() {
            this.toastMessage = "Running API Import ...";
            $("#toast").toast({ autohide: false });
            $("#toast").toast("show");

            HTTP.get("/api/run-api-import")
                .then((response) => {
                    this.toastMessage = "API Import Finished";
                    setTimeout(() => {
                        $("#toast").toast("hide");
                    }, 2000);
                })
                .catch((e) => {
                    this.toastMessage = "API Import Failed";
                });
        },
        runCardsImport() {
            this.toastMessage = "Running Cards Import ...";
            $("#toast").toast({ autohide: false });
            $("#toast").toast("show");

            HTTP.get("/api/run-cards-import")
                .then((response) => {
                    this.toastMessage = "Cards Import Finished";
                    setTimeout(() => {
                        $("#toast").toast("hide");
                    }, 2000);
                })
                .catch((e) => {
                    this.toastMessage = "Cards Import Failed";
                });
        },
        changeCardsTablePage(index) {
            this.page = index;
            this.getCards();
        },
        changeCardsLength() {
            this.page = 1;
            this.getCards();
        },
        searchCards() {
            this.getCards();
        },
        sortCardsColumns(column) {
            this.cardsTableSettings.orderBy = column;

            let keys = Object.keys(this.cardsTableSettings.columns);
            for (const key of keys) {
                if (key === column) {
                    if (this.cardsTableSettings.columns[key].dir === "asc") {
                        this.cardsTableSettings.columns[key].dir = "desc";
                        this.cardsTableSettings.sortDir = "desc";
                    } else {
                        this.cardsTableSettings.columns[key].dir = "asc";
                        this.cardsTableSettings.sortDir = "asc";
                    }
                } else {
                    this.cardsTableSettings.columns[key].dir = "asc";
                }
            }

            setTimeout(() => {
                this.getCards();
            }, 100);
        },

        getSales() {
            this.tableTotal = 0.0;
            this.showLoading();
            let url =
                "/api/sales?page=" +
                this.page +
                "&order_by=" +
                this.salesTableSettings.orderBy +
                "&sort_dir=" +
                this.salesTableSettings.sortDir +
                "&length=" +
                this.length +
                "&search_query=" +
                this.searchQuery +
                "&start_date=" +
                this.startDate +
                "&end_date=" +
                this.endDate;

            HTTP.get(url)
                .then((response) => {
                    this.sales = response.data.sales.data;
                    this.total = response.data.sales.total;
                    this.lastPage = response.data.sales.last_page;
                    this.currentPage = response.data.sales.current_page;
                    this.referralCount = response.data.referrals_count;
                    this.earningsPerClick = parseFloat(
                        response.data.earnings_per_click
                    );
                    this.creditCardClicks = response.data.credit_card_clicks;
                    this.statsRevenue = response.data.revenue;
                    this.clickThruRate = parseFloat(
                        response.data.click_thru_rate
                    );
                    this.affiliates = response.data.affiliates;
                    this.hideLoading();

                    this.sales.forEach((v) => {
                        this.tableTotal += parseFloat(v.sale_commission);
                    });
                })
                .catch((e) => {
                    console.log(e);
                    this.hideLoading();
                });
        },
        changeSalesTablePage(index) {
            this.page = index;
            this.getSales();
        },
        changeSalesLength() {
            this.page = 1;
            this.getSales();
        },
        searchSales() {
            this.getSales();
        },
        sortSalesColumns(column) {
            this.salesTableSettings.orderBy = column;

            let keys = Object.keys(this.salesTableSettings.columns);
            for (const key of keys) {
                if (key === column) {
                    if (this.salesTableSettings.columns[key].dir === "asc") {
                        this.salesTableSettings.columns[key].dir = "desc";
                        this.salesTableSettings.sortDir = "desc";
                    } else {
                        this.salesTableSettings.columns[key].dir = "asc";
                        this.salesTableSettings.sortDir = "asc";
                    }
                } else {
                    this.salesTableSettings.columns[key].dir = "asc";
                }
            }

            setTimeout(() => {
                this.getSales();
            }, 100);
        },

        getClicks() {
            this.tableTotal = 0.0;
            this.showLoading();
            let url =
                "/api/clicks?page=" +
                this.page +
                "&order_by=" +
                this.clicksTableSettings.orderBy +
                "&sort_dir=" +
                this.clicksTableSettings.sortDir +
                "&length=" +
                this.length +
                "&search_query=" +
                this.searchQuery +
                "&start_date=" +
                this.startDate +
                "&end_date=" +
                this.endDate;

            HTTP.get(url)
                .then((response) => {
                    this.clicks = response.data.clicks.data;
                    this.total = response.data.clicks.total;
                    this.currentPage = response.data.clicks.current_page;
                    this.lastPage = response.data.clicks.last_page;
                    this.hideLoading();
                })
                .catch((e) => {
                    console.log(e);
                    this.hideLoading();
                });
        },
        runClicksImport() {
            this.toastMessage = "Running Clicks Import ...";
            $("#toast").toast({ autohide: false });
            $("#toast").toast("show");

            HTTP.get("/api/run-clicks-import")
                .then((response) => {
                    this.toastMessage = "Clicks Import Finished";
                    setTimeout(() => {
                        $("#toast").toast("hide");
                    }, 2000);
                })
                .catch((e) => {
                    this.toastMessage = "Clicks Import Failed";
                });
        },
        changeClicksTablePage(index) {
            this.page = index;
            this.getClicks();
        },
        changeClicksLength() {
            this.page = 1;
            this.getClicks();
        },
        searchClicks() {
            this.getClicks();
        },
        sortClicksColumns(column) {
            this.clicksTableSettings.orderBy = column;

            let keys = Object.keys(this.clicksTableSettings.columns);
            for (const key of keys) {
                if (key === column) {
                    if (this.clicksTableSettings.columns[key].dir === "asc") {
                        this.clicksTableSettings.columns[key].dir = "desc";
                        this.clicksTableSettings.sortDir = "desc";
                    } else {
                        this.clicksTableSettings.columns[key].dir = "asc";
                        this.clicksTableSettings.sortDir = "asc";
                    }
                } else {
                    this.clicksTableSettings.columns[key].dir = "asc";
                }
            }

            setTimeout(() => {
                this.getClicks();
            }, 100);
        },

        updateClicksList() {
            if (this.startDate && this.endDate) {
                this.getClicks();
            }
        },

        updateSalesList() {
            if (this.startDate && this.endDate) {
                this.getSales();
                if (this.$refs.hasOwnProperty("cardStatsAdmin")) {
                    this.$refs.cardStatsAdmin.getData();
                }
            }
        },
        updateSaleCustomerId(sale) {
            let url = "/api/sales/" + sale.id;
            let form = new FormData();
            form.append("_method", "PUT");
            form.append("customer_id", sale.customer_id);

            HTTP.post(url, form)
                .then((response) => {
                    this.showToast("Tacking ID updated");
                })
                .catch((e) => {
                    console.log(e);
                    this.showToast(e.message);
                });
        },
        getMonthly() {
            this.showLoading();
            let url =
                "/api/sub-affiliates/" +
                userId +
                "/monthly-sales?page=" +
                this.page +
                "&order_by=" +
                this.monthlySalesTableSettings.orderBy +
                "&sort_dir=" +
                this.monthlySalesTableSettings.sortDir +
                "&length=" +
                this.length +
                "&search_query=" +
                this.searchQuery +
                "&start_date=" +
                this.startDate +
                "&end_date=" +
                this.endDate;

            HTTP.get(url)
                .then((response) => {
                    this.monthlySales = response.data.monthly_sales.data;
                    this.total = response.data.monthly_sales.total;
                    this.lastPage = response.data.monthly_sales.last_page;
                    this.lifeTimeRevenue = response.data.lifetime_revenue;
                    this.lifeTimeCommission = response.data.lifetime_commission;
                    this.lifeTimeMvCommission =
                        response.data.lifetime_mv_commission;
                    this.lifeTimeCardSales = response.data.lifetime_card_sales;
                    this.referralCount = response.data.referral_count;
                    this.clickCount = response.data.click_count;
                    this.hideLoading();
                })
                .catch((e) => {
                    console.log(e);
                    this.hideLoading();
                });
        },
        changeMonthlyTablePage(index) {
            this.page = index;
            this.getMonthly();
        },
        changeMonthlyLength() {
            this.page = 1;
            this.getMonthly();
        },
        searchMonthly() {
            this.getMonthly();
        },
        sortMonthlyColumns(column) {
            this.monthlySalesTableSettings.orderBy = column;

            let keys = Object.keys(this.monthlySalesTableSettings.columns);
            for (const key of keys) {
                if (key === column) {
                    if (
                        this.monthlySalesTableSettings.columns[key].dir ===
                        "asc"
                    ) {
                        this.monthlySalesTableSettings.columns[key].dir =
                            "desc";
                        this.monthlySalesTableSettings.sortDir = "desc";
                    } else {
                        this.monthlySalesTableSettings.columns[key].dir = "asc";
                        this.monthlySalesTableSettings.sortDir = "asc";
                    }
                } else {
                    this.monthlySalesTableSettings.columns[key].dir = "asc";
                }
            }

            setTimeout(() => {
                this.getMonthly();
            }, 100);
        },
        updateMonthlyList() {
            if (this.startDate && this.endDate) {
                this.getMonthly();
            }
        },
        showPageAmount() {
            let start = this.page * this.length - this.length;
            let count = this.page * this.length;
            if (count > this.total) {
                count = this.total;
            }
            if (start === 0) {
                start = 1;
            }
            return start + " - " + count;
        },
        getActivity() {
            this.showLoading();
            let url =
                "/api/sub-affiliates/" +
                userId +
                "/activity?page=" +
                this.activityPage;

            HTTP.get(url)
                .then((response) => {
                    this.activities = response.data;
                    //this.activityTotal = response.data.total;
                    //this.activityLastPage = response.data.last_page;
                    this.hideLoading();
                })
                .catch((e) => {
                    //console.log('sort error');
                });
        },
        getAffiliates() {
            this.showLoading();
            HTTP.get(
                "/api/affiliates?page=" +
                this.page +
                "&order_by=" +
                this.affiliatesTableSettings.orderBy +
                "&sort_dir=" +
                this.affiliatesTableSettings.sortDir +
                "&length=" +
                this.length +
                "&search_query=" +
                this.searchQuery +
                "&filter_name=status" +
                "&filter_value=" +
                this.affiliatesTableSettings.filters.status
            )
                .then((response) => {
                    this.affiliates = response.data.affiliates.data;
                    this.total = response.data.affiliates.total;
                    this.lastPage = response.data.affiliates.last_page;
                    this.hideLoading();
                })
                .catch((e) => {
                    //console.log('sort error');
                });
        },
        getFilteredUsers() {
            this.showLoading();
            HTTP.get(
                "/api/users?page=" +
                this.page +
                "&order_by=" +
                this.tableUserConfig.orderBy +
                "&sort_dir=" +
                this.tableUserConfig.dir +
                "&length=" +
                this.length +
                "&search_query=" +
                this.searchQuery +
                "&role_id=" +
                this.tableUserConfig.extraParams.role_id.value
            )
                .then((response) => {
                    this.$refs.dataTable.update();
                    this.hideLoading();
                })
                .catch((e) => {
                    //console.log('sort error');
                });
        },
        changeAffiliatesTablePage(index) {
            this.page = index;
            this.getAffiliates();
        },
        changeAffiliatesLength() {
            this.page = 1;
            this.getAffiliates();
        },
        sortAffiliatesColumns(column) {
            this.affiliatesTableSettings.orderBy = column;

            let keys = Object.keys(this.affiliatesTableSettings.columns);
            for (const key of keys) {
                if (key === column) {
                    if (
                        this.affiliatesTableSettings.columns[key].dir === "asc"
                    ) {
                        this.affiliatesTableSettings.columns[key].dir = "desc";
                        this.affiliatesTableSettings.sortDir = "desc";
                    } else {
                        this.affiliatesTableSettings.columns[key].dir = "asc";
                        this.affiliatesTableSettings.sortDir = "asc";
                    }
                } else {
                    this.affiliatesTableSettings.columns[key].dir = "asc";
                }
            }

            setTimeout(() => {
                this.getAffiliates();
            }, 100);
        },
        searchAffiliates() {
            this.getAffiliates();
        },
        getMessages() {
            this.showLoading();
            HTTP.get(
                "/api/messages?page=" +
                this.page +
                "&order_by=" +
                this.messagesTableSettings.orderBy +
                "&sort_dir=" +
                this.messagesTableSettings.sortDir +
                "&length=" +
                this.length +
                "&search_query=" +
                this.searchQuery +
                "&filter_name=status" +
                "&filter_value=" +
                this.messagesTableSettings.filters.status
            )
                .then((response) => {
                    this.messages = response.data.messages.data;
                    this.total = response.data.messages.total;
                    this.lastPage = response.data.messages.last_page;
                    this.hideLoading();
                })
                .catch((e) => {
                    //console.log('sort error');
                });
        },
        changeMessagesTablePage(index) {
            this.page = index;
            this.getMessages();
        },
        changeMessagesLength() {
            this.page = 1;
            this.getMessages();
        },
        sortMessagesColumns(column) {
            this.messagesTableSettings.orderBy = column;

            let keys = Object.keys(this.messagesTableSettings.columns);
            for (const key of keys) {
                if (key === column) {
                    if (this.messagesTableSettings.columns[key].dir === "asc") {
                        this.messagesTableSettings.columns[key].dir = "desc";
                        this.messagesTableSettings.sortDir = "desc";
                    } else {
                        this.messagesTableSettings.columns[key].dir = "asc";
                        this.messagesTableSettings.sortDir = "asc";
                    }
                } else {
                    this.messagesTableSettings.columns[key].dir = "asc";
                }
            }

            setTimeout(() => {
                this.getMessages();
            }, 100);
        },
        searchMessages() {
            this.getMessages();
        },
        updateDataTable() {
            this.$refs.dataTable.searchQuery = this.searchQuery;
            this.$refs.dataTable.update();
        },
        generateReports() {
            if (this.reportMonth && this.reportYear) {
                this.showLoading();
                HTTP.get(
                    "/api/generate-reports?month=" +
                    this.reportMonth +
                    "&year=" +
                    this.reportYear +
                    "&customer_id=" +
                    this.customerId
                )
                    .then((response) => {
                        this.hideLoading();
                        this.$refs.dataTable.page = 1;
                        this.$refs.dataTable.update();

                        $("#reports-modal").modal("hide");

                        this.showToast("Reports generation complete.");
                    })
                    .catch((e) => {
                        this.hideLoading();
                    });
            }
        },
        sendReport(e) {
            this.reportSending = true;
            const reportId = e.target.getAttribute("data-report-id");
            HTTP.get("/dashboard-admin/sales-reports/" + reportId + "/send")
                .then((response) => {
                    this.reportSending = false;
                    this.showToast("Report sent.");
                })
                .catch((e) => {
                    this.reportSending = false;
                });
        },
        sendReports(e) {
            this.reportSending = true;
            const token = document.querySelector(
                'meta[name="csrf-token"]'
            ).content;
            let formData = new FormData();
            formData.append("month", this.reportMonth);
            formData.append("year", this.reportYear);
            formData.append("_token", token);

            HTTP.post("/dashboard-admin/sales-reports/send-all", formData)
                .then((response) => {
                    this.reportSending = false;
                    this.showToast("Reports sent.");
                })
                .catch((e) => {
                    this.reportSending = false;
                });
        },
        parseReportStatus(status) {
            switch (status) {
                case "DRAFT":
                    status = '<span class="report-status draft">Draft</span>';
                    break;
                case "PENDING":
                    status =
                        '<span class="report-status pending">Pending</span>';
                    break;
                case "SENT":
                    status = '<span class="report-status sent">Sent</span>';
                    break;
                case "PAID":
                    status = '<span class="report-status paid">Paid</span>';
                    break;
            }

            return status;
        },
        getSalesReportChart() {
            this.salesReportChartLoaded = false;

            this.salesReportChartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 12,
                        bottom: 0,
                    },
                },
                title: {
                    display: false,
                    text: "Sales Report",
                    fontSize: 20,
                    fontColor: "#222",
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return currency(tooltipItem.yLabel).format();
                        },
                    },
                },
                legend: {
                    display: true,
                    align: "end",
                    labels: {
                        fontColor: "#222",
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                borderDash: [3, 2],
                            },
                            ticks: {
                                callback: function (value, index, values) {
                                    return value.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    });
                                },
                            },
                        },
                    ],
                },
            };

            HTTP.get(
                "/api/salesreportchart?start_date=" +
                this.startDate +
                "&end_date=" +
                this.endDate
            )
                .then((response) => {
                    this.salesReportChartLoaded = true;

                    const labels = response.data.labels;
                    const grossSales = response.data.gross_sales;
                    const affiliateSales = response.data.affiliate_sales;
                    const mvSales = response.data.mv_sales;

                    this.salesReportChartData = {
                        labels: labels,
                        datasets: response.data.data_set,
                    };
                })
                .catch((e) => {
                    console.log("Sales report chart error ", e);
                    this.salesReportChartLoaded = true;
                });
        },
        getTrafficReportChart() {
            this.trafficReportChartLoaded = false;

            this.trafficReportChartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 12,
                        bottom: 0,
                    },
                },
                title: {
                    display: false,
                    text: "Traffic Report",
                    fontSize: 20,
                    fontColor: "#222",
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel;
                            //return currency(tooltipItem.yLabel).format();
                        },
                    },
                },
                legend: {
                    display: true,
                    align: "end",
                    labels: {
                        fontColor: "#222",
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                borderDash: [3, 2],
                            },
                            ticks: {
                                callback: function (value, index, values) {
                                    return value;
                                    //return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                                },
                            },
                        },
                    ],
                },
            };

            HTTP.get(
                "/api/trafficreportchart?start_date=" +
                this.startDate +
                "&end_date=" +
                this.endDate
            )
                .then((response) => {
                    this.trafficReportChartLoaded = true;

                    const labels = response.data.labels;
                    const grossSales = response.data.gross_sales;
                    const affiliateSales = response.data.affiliate_sales;
                    const mvSales = response.data.mv_sales;

                    this.trafficReportChartData = {
                        labels: labels,
                        datasets: response.data.data_set,
                    };
                })
                .catch((e) => {
                    console.log("Sales report chart error ", e);
                    this.salesReportChartLoaded = true;
                });
        },
        updatedDashboardComponents() {
            this.$refs.topSellingCards.fromDashboard = true;
            if (this.$refs.lastPayment) {
                this.$refs.lastPayment.fromDashboard = true;
                this.$refs.lastPayment.getData();
            }

            this.$refs.topSellingCards.getData();

            if (this.$refs.hasOwnProperty("affiliatePerformance")) {
                this.$refs.affiliatePerformance.getData();
            }
            if (this.$refs.hasOwnProperty("salesReportChart")) {
                this.getSalesReportChart();
            }
            if (this.$refs.hasOwnProperty("trafficReportChart")) {
                this.getTrafficReportChart();
            }
            if (this.$refs.hasOwnProperty("cardStatsAdmin")) {
                this.$refs.cardStatsAdmin.getData();
            }
        },
        updateCardsList() {
            this.$refs.topSellingCards.getData();
        },
        showToast(message) {
            this.toastMessage = message;
            $("#toast").toast("show");
            setTimeout(() => {
                this.toastMesage = "";
            }, 2200);
        },
        showLoading() {
            setTimeout(() => {
                this.loading = true;
            });
        },
        hideLoading() {
            setTimeout(() => {
                this.loading = false;
            }, 1100);
        },
        isTouchDevice() {
            return (
                "ontouchstart" in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0
            );
        },
    },
    created() {
        this.updateDataTable = window._.debounce(
            this.updateDataTable,
            this.searchKeyupTime
        );
        this.searchCards = window._.debounce(
            this.searchCards,
            this.searchKeyupTime
        );
        this.searchClicks = window._.debounce(
            this.searchClicks,
            this.searchKeyupTime
        );
        this.searchSales = window._.debounce(
            this.searchSales,
            this.searchKeyupTime
        );
        this.searchAffiliates = window._.debounce(
            this.searchAffiliates,
            this.searchKeyupTime
        );
        let date = new Date();

        let sDate = new Date(date.getFullYear(), date.getMonth(), 1)
            .toISOString()
            .slice(0, 10);
        let eDate = new Date().toISOString().slice(0, 10);

        if (Cookies.get("sdate")) {
            sDate = Cookies.get("sdate");
        }

        if (Cookies.get("edate")) {
            eDate = Cookies.get("edate");
        }

        this.startDate = sDate;
        this.endDate = eDate;

        this.performanceTableConfig.extraParams.start_date.value = sDate;
        this.performanceTableConfig.extraParams.end_date.value = eDate;
    },
    mounted() {
        let self = this;
        const datePicker = flatpickr(".date-picker", {
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                const updateMethod =
                    instance.element.getAttribute("data-callback");
                if (updateMethod && updateMethod.length) {
                    setTimeout(() => {
                        if (instance.element.name === "start_date") {
                            Cookies.set("sdate", dateStr, { expires: 7 });
                        }
                        if (instance.element.name === "end_date") {
                            Cookies.set("edate", dateStr, { expires: 7 });
                        }

                        self[updateMethod]();
                    }, 100);
                }
            },
        });

        let $cardsTable = document.querySelector("#cards-table");
        let $clicksTable = document.querySelector("#clicks-table");
        let $salesTable = document.querySelector("#sales-table");
        let $affiliatesTable = document.querySelector("#affiliates-table");
        let $monthlySalesTable = document.querySelector("#monthly-sales-table");
        let $activityList = document.querySelector("#activity-list");

        if ($cardsTable) {
            this.getCards();
        }

        if ($clicksTable) {
            this.getClicks();
        }

        if ($salesTable) {
            this.getSales();
        }

        if ($affiliatesTable) {
            this.getAffiliates();
        }

        if ($monthlySalesTable) {
            this.getMonthly();
        }

        if ($activityList) {
            this.getActivity();
        }

        $('[data-toggle="tooltip"]').tooltip();

        if (this.isTouchDevice()) {
            let $menu = document.getElementById("navbarSupportedContent");
            $menu.classList.remove("show");
        }

        $(".dashboard-nav .has-dropdown").on("touchend click", function (e) {
            if (e.type === "touchend") {
                $(this).off("click");

                if ($(this).hasClass("open")) {
                    setTimeout(() => {
                        $(this).removeClass("open");
                    }, 250);
                } else {
                    $(this).addClass("open");
                }
            } else if (e.type === "click") {
                $(this).off("touchstart");

                if ($(this).hasClass("open")) {
                    $(this).removeClass("open");
                } else {
                    $(this).addClass("open");
                }
            }
        });

        const $exportPerformanceBtn = document.querySelector(
            ".export-performance-btn"
        );

        if ($exportPerformanceBtn) {
            $exportPerformanceBtn.addEventListener("click", (el) => {
                el.preventDefault();
                let $form = document.getElementById("performance-export-form");

                const startDate =
                    this.performanceTableConfig.extraParams.start_date.value;
                const endDate =
                    this.performanceTableConfig.extraParams.end_date.value;

                let $inputStart = document.createElement("input");
                let $inputEnd = document.createElement("input");

                $inputStart.value = startDate;
                $inputStart.name = "start_date";
                $inputStart.type = "hidden";

                $inputEnd.value = endDate;
                $inputEnd.name = "end_date";
                $inputEnd.type = "hidden";

                $form.appendChild($inputStart);
                $form.appendChild($inputEnd);

                $form.submit();
            });
        }

        const $hasSalesReportChart = document.querySelector(
            ".has-sales-report-chart"
        );
        if ($hasSalesReportChart) {
            this.getSalesReportChart();
        }
        const $hasTrafficReportChart = document.querySelector(
            ".has-traffic-report-chart"
        );
        if ($hasTrafficReportChart) {
            this.getTrafficReportChart();
        }

        $("body").on("change", ".toggle-status-select", (el) => {
            let val = el.target.value;
            let arr = val.split(":");
            let id = arr[0];
            let status = arr[1];
            if (status == "PAID") {
                HTTP.get("/sales-reports/" + id)
                    .then((response) => {
                        if (response.data.html) {
                            $(".dy-model").html(response.data.html);
                            $("#paymentModal").modal("show");
                            $("input#override_amount").prop(
                                "disabled",
                                !this.checked
                            );
                            $("#is_override_payment").click(function () {
                                $("input#override_amount").prop(
                                    "disabled",
                                    !$(this).prop("checked")
                                );
                            });
                        }
                    })
                    .catch((e) => {
                        console.log("Change status error ", e);
                    });
            } else {
                $("#paymentModal").modal("hide");
                HTTP.get("/api/reports/monthly/" + id + "/status/" + status)
                    .then((response) => {
                        this.showToast("Status updated!");
                    })
                    .catch((e) => {
                        console.log("Change status error ", e);
                    });
            }
        });

        $(document).on("click", ".sale-report", function () {
            var id = $(this).data("value");
            HTTP.get("/sales-reports/" + id)
                .then((response) => {
                    if (response.data.html) {
                        $(".dy-model").html(response.data.html);
                        $("#paymentModal").modal("show");
                        $("input#override_amount").prop(
                            "disabled",
                            !this.checked
                        );
                        $("#is_override_payment").click(function () {
                            $("input#override_amount").prop(
                                "disabled",
                                !$(this).prop("checked")
                            );
                        });
                    }
                })
                .catch((e) => {
                    console.log("Change status error ", e);
                });
        });

        $(document).on("click", ".payment-detail", function () {
            var id = $(this).data("value");
            HTTP.get("/payments/" + id)
                .then((response) => {
                    if (response.data.html) {
                        $(".dy-model").html(response.data.html);
                        $("#editPaymentModal").modal("show");
                        $("input#override_amount").prop(
                            "disabled",
                            !$("#is_override_payment").prop("checked")
                        );
                        $("#is_override_payment").click(function () {
                            $("input#override_amount").prop(
                                "disabled",
                                !$(this).prop("checked")
                            );
                        });
                    }
                })
                .catch((e) => {
                    console.log("Change status error ", e);
                });
        });

        $(document).on("blur", ".numeric-input", function () {
            let inputValue = $(this).val();
            let cleanedValue = inputValue.replace(/[^\d.-]/g, '');
            let numberValue = parseFloat(cleanedValue) || 0;
            let formattedValue = new Intl.NumberFormat('en-US').format(numberValue);

            // Set the formatted value back in the input field
            $(this).val(formattedValue);
        });

        $(document).on("click", ".syncCardBtn", function () {
            var cardId = $(this).attr("data-card-id");
            var button = $(this); 
            var originalContent = button.html();
            var loader = '<i class="fas fa-spinner fa-spin"></i>'; 
        
            $('#sync-message').addClass('d-none').html('');
        
            button.html(loader + ' Syncing...').prop('disabled', true);
        
            setTimeout(function() {
                $.ajax({
                    url: '/cards/sync-card/' + cardId,
                    method: 'GET',
                    success: function(response) {
                        $('#sync-message').removeClass('d-none').html(
                            '<div class="alert alert-success">' + response.message + '</div>'
                        );
                        $('html, body').animate({ scrollTop: 0 }, 'slow');
                        
                        setTimeout(function() {
                            $('#sync-message').addClass('d-none');
                            window.location.reload();
                        }, 3000);
                    },
                    error: function(xhr) {
                        var errorMessage = xhr.responseJSON && xhr.responseJSON.message 
                            ? xhr.responseJSON.message 
                            : 'Error syncing card';
        
                        $('#sync-message').removeClass('d-none').html(
                            '<div class="alert alert-danger">' + errorMessage + '</div>'
                        );
                        $('html, body').animate({ scrollTop: 0 }, 'slow');
        
                        setTimeout(function() {
                            $('#sync-message').addClass('d-none');
                        }, 10000);
                    },
                    complete: function() {
                        button.html(originalContent).prop('disabled', false);
                    }
                });
            }, 50);
        });
        
        // $(document).on("click", "#preview_link", function () {
        //     console.log("preview");
        //     document.getElementById("draft").value = "0";
        //     document.getElementById("preview").value = "1";
        //     document.getElementById("message-create").submit();
        // });

        // $(document).on("click", "#draft_link", function () {
        //     console.log("draft save");
        //     document.getElementById("preview").value = "0";
        //     document.getElementById("draft").value = "1";
        //     document.getElementById("message-create").submit();
        // });
    },
});
