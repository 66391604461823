<template>
    <div class="rep-commissions">
        <h5 class="mb-3 pb-3 border-bottom">
            Sales Rep Details
            <button type="button" class="btn btn-secondary btn-sm" @click="addCommissionFields">
                Add Sales Rep Commission
            </button>
        </h5>
        <table class="table assigned-commissions">
            <tbody>
                <tr v-for="(comm, index) in commissions">
                    <td>
                        <div class="form-group">
                            <label :for="'percent-' + comm.id">Percent</label>
                            <input
                                :id="'percent-' + comm.id"
                                class="form-control"
                                type="number"
                                step=".5"
                                :name="'commission_percents[' + comm.id + ']'"
                                :value="comm.percent"
                                required
                            />
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <label :for="'start-date-' + comm.id">Start Effective Date</label>
                            <input
                                :id="'start-date-' + comm.id"
                                class="form-control"
                                type="date"
                                :name="'commission_start_dates[' + comm.id + ']'"
                                :value="comm.start_date"
                                required
                            />
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <label :for="'end-date-' + comm.id">End Effective Date</label>
                            <input
                                :id="'end-date-' + comm.id"
                                class="form-control"
                                type="date"
                                :name="'commission_end_dates[' + comm.id + ']'"
                                :value="comm.end_date"
                            />
                        </div>
                    </td>
                    <td class="pt-4">
                        <a
                            v-if="comm.new"
                            class="btn btn-light confirm-delete"
                            href="#"
                            @click.prevent="removeNewRow(index)"
                            ><i class="far fa-times"></i
                        ></a>
                        <a
                            v-if="!comm.new"
                            :href="
                                '/dashboard-admin/sales-reps/' +
                                affId +
                                '/commissions/' +
                                comm.id +
                                '/delete'
                            "
                            ><i class="far fa-times"></i
                        ></a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="card-loader" v-if="loading" v-cloak>
            <i class="far fa-spinner-third fa-spin"></i>
        </div>
    </div>
</template>

<script>
const axios = require('axios');

var appElement = document.getElementById('app');
var apiToken = appElement.getAttribute('data-api-token');

const HTTP = axios.create(
    (axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': app.csrfToken,
        Authorization: 'Bearer ' + apiToken,
        'Content-Type': 'multipart/form-data',
    })
);

module.exports = {
    props: ['affId'],
    data: function () {
        return {
            loading: true,
            commissions: [],
        };
    },
    methods: {
        getData() {
            this.loading = true;

            const url = '/api/rep-commissions/' + this.affId;

            HTTP.get(url)
                .then((response) => {
                    this.commissions = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log('Commissions error', e);
                    this.loading = false;
                });
        },
        addCommissionFields() {
            const id = this.randomId();
            let obj = { percent: '', start_date: '', end_date: '', new: true, id: id };
            this.commissions.unshift(obj);
        },
        randomId() {
            return '_' + Math.random().toString(36).substr(2, 9);
        },
        removeNewRow(i) {
            this.commissions.splice(i, 1);
        },
    },
    created() {},
    mounted() {
        this.getData();
    },
};
</script>

<style scoped>
.rep-commissions {
    position: relative;
}
</style>
