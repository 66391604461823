<template>
    <div class="dashboard-card has-inner top-cards">
        <div class="inner">
            <div class="dashboard-card-header">
                <div class="row">
                    <div class="col-md-7">
                        <h4 class="m-0">Latest Payment</h4>
                    </div>
                </div>
            </div>
            <div class="dashboard-card-body">
                <ul class="card-list">
                    <li class="card-row">
                        <span class="card-card-name">Payment </span><span class="card-card-values"><span v-for="card in cardItems" :key="card.payment_amount" class="card-card-total">{{ card.is_override_payment != 0 ? card.override_amount : card.payment_amount | formatMoney }}</span>&nbsp;</span>
                    </li>
                    <li class="card-row">
                        <span class="card-card-name">Payment Date </span> <span class="card-card-values"><span v-for="card in cardItems" :key="card.payment_date" class="card-card-total">{{ card.payment_date }}</span>&nbsp;</span>
                    </li>
                     <li class="card-row">
                        <span class="card-card-name">Description</span> <span class="card-card-values"><span v-for="card in cardItems" :key="card.description" class="card-card-total">{{ card.description }}</span>&nbsp;</span>
                    </li>
                    <li class="card-row">
                        <span class="card-card-name">Invoice </span> <span class="card-card-values"><span v-for="card in cardItems" :key="card.invoice_no" class="card-card-total"><a :href="getInvoiceLink(card.invoice_no)" target="_blank">{{card.report_type}} {{ card.invoice_no }}</a></span>&nbsp;</span>
                    </li>
                </ul>
                <div class="card-loader" v-if="loading" v-cloak><i class="far fa-spinner-third fa-spin"></i></div>
            </div>
            <div class="dashboard-card-footer">
                <a type="button" class="btn btn-secondary btn-export" href="/payments" >View All</a>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
let currency = require('currency.js');

var appElement = document.getElementById('app');
var apiToken = appElement.getAttribute('data-api-token');

const HTTP = axios.create(axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : app.csrfToken,
    'Authorization' : 'Bearer '+apiToken,
    'Content-Type': 'multipart/form-data'
});

module.exports = {
    props: [  ],
    data: function() {
        return {
            loading: true,
            length: 8,
            showAll: false,
            fromDashboard: false,
            sDate: null,
            eDate: null,
            cards: [],
        };
    },
    computed: {
        cardItems(){
            let length = !this.showViewAll? this.cards.length : this.length;
            return this.cards;
        }
    },
    filters: {
        formatMoney(value){
            if (!value) return '';
            return currency(value).format();
        },
    },
    methods: {
        getData(){
            this.loading = true;
            const url = '/api/recent-payment';

            HTTP.get(url)
            .then(response => {
                this.cards = response.data;
                this.loading = false;
            })
            .catch(e => {
                console.log('Last payment error', e);
                this.loading = false;
            });
        },
        toggleList: function(){
            this.showAll = !this.showAll;
        },
        getInvoiceLink(invoiceNo) {
       return `/sales-reports/${invoiceNo}`;
    },
    },
    created(){

    },
    mounted(){
        let self = this;
        this.sDate = this.startDate;
        this.eDate = this.endDate;
        const datePickerComponent = flatpickr('.date-picker-component', { dateFormat: 'Y-m-d', onChange: function(selectedDates, dateStr, instance) {
            console.log(instance);
            const updateMethod = instance.element.getAttribute('data-callback');
            if( updateMethod.length ){

                if( instance.element.name === 'start_date' ){
                    Cookies.set('sdate', dateStr, { expires: 7 });
                }
                if( instance.element.name === 'end_date'){
                    Cookies.set('edate', dateStr, { expires: 7 });
                }

                setTimeout(()=>{
                    self[updateMethod]();
                }, 100)
            }
        }
        });

        this.getData();

    }
};
</script>

<style scoped>
.top-cards{
    margin-bottom: 32px;
}
.card-card-name{
    font-size: .875rem;
    padding: 0 6px;
    max-width: 300px;
}
.card-card-values{
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    min-width: 125px;
}
.card-card-total{
    font-weight: 600;
    font-size: .875rem;
}
.card-card-count{
    font-size: .75rem;
}
</style>
