<template>
    <div class="cards-group" v-bind:class="{ 'three-col': config.three_col }">
        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of revenue combined generated by cards sold for the time period selected"
            v-if="config.revenue"
            v-cloak
        >
            <h6 class="card-title-sm">Total Credit Card Revenue</h6>
            <p
                class="card-value mb-0"
                v-if="cardStats.total_credit_card_revenue"
                v-cloak
            >
                {{ cardStats.total_credit_card_revenue | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_credit_card_revenue_change"
                    v-cloak
                    >{{ cardStats.total_credit_card_revenue_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The number of cards sold for the time period selected"
            v-if="config.card_sales"
            v-cloak
        >
            <h6 class="card-title-sm">Number of Credit Card Sales</h6>
            <p
                class="card-value mb-0"
                v-if="cardStats.number_of_credit_card_sales"
                v-cloak
            >
                {{ cardStats.number_of_credit_card_sales | formatThousand }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.number_of_credit_card_sales_change"
                    v-cloak
                    >{{ cardStats.number_of_credit_card_sales_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            v-if="config.mv_direct_sales"
            v-cloak
        >
            <h6 class="card-title-sm">MileValue Direct Sales</h6>
            <p class="card-value mb-0" v-if="cardStats.mv_direct_sales">
                {{ cardStats.mv_direct_sales | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.mv_direct_sales_change"
                    v-cloak
                    >{{ cardStats.mv_direct_sales_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of commission generated by card sold during the time period selected"
            v-if="config.affiliate_commission"
            v-cloak
        >
            <h6 class="card-title-sm">
                <span v-if="!config.is_affiliate">Sub-Affiliate</span>
                Commission
            </h6>
            <p
                class="card-value mb-0"
                v-if="cardStats.affiliate_commission"
                v-cloak
            >
                {{ cardStats.affiliate_commission | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.affiliate_commission_change"
                    v-cloak
                    >{{ cardStats.affiliate_commission_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of commission generated by card sold during the time period selected"
            v-if="config.mv_commission"
            v-cloak
        >
            <h6 class="card-title-sm">MileValue Commission</h6>
            <p class="card-value mb-0" v-if="cardStats.mv_commission" v-cloak>
                {{ cardStats.mv_commission | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.mv_commission_change"
                    v-cloak
                    >{{ cardStats.mv_commission_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of commission generated by card sold during the time period selected"
            v-if="config.rep_commission"
            v-cloak
        >
            <h6 class="card-title-sm">Sales Rep Commission</h6>
            <p class="card-value mb-0" v-if="cardStats.rep_commission" v-cloak>
                {{ cardStats.rep_commission | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.rep_commission_change"
                    v-cloak
                >
                    {{ cardStats.rep_commission_change }}
                </span>
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            v-if="config.total_profit"
            v-cloak
        >
            <h6 class="card-title-sm">Total Profit</h6>
            <p class="card-value mb-0" v-if="cardStats.total_profit" v-cloak>
                {{ cardStats.total_profit | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_profit_change"
                    v-cloak
                    >{{ cardStats.total_profit_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The number of clicks from MileValue to the product page on CreditCards.com using your tracking links for the time period selected"
            v-if="config.referrals"
            v-cloak
        >
            <h6 class="card-title-sm">Total Referrals</h6>
            <p class="card-value mb-0" v-if="cardStats.total_referrals" v-cloak>
                {{ cardStats.total_referrals | formatThousand }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_referrals_change"
                    v-cloak
                    >{{ cardStats.total_referrals_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="This is the number of clicks from the product page on CreditCards.com to the application using your tracking links for the time period selected"
            v-if="config.clicks"
            v-cloak
        >
            <h6 class="card-title-sm">Total Card Clicks</h6>
            <p
                class="card-value mb-0"
                v-if="cardStats.total_card_click"
                v-cloak
            >
                {{ cardStats.total_card_click | formatThousand }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_card_click_change"
                    v-cloak
                    >{{ cardStats.total_card_click_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of revenue combined generated by cards sold divided by the number of Credit Card Clicks for the time period selected"
            v-if="config.eaernings"
            v-cloak
        >
            <h6 class="card-title-sm">Earnings Per Click</h6>
            <p
                class="card-value mb-0"
                v-if="cardStats.earnings_per_click"
                v-cloak
            >
                {{ cardStats.earnings_per_click | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.earnings_per_click_change"
                    v-cloak
                    >{{ cardStats.earnings_per_click_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <!--<div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of Credit Card Clicks divided by the number of Referrals for the time period selected"
            v-if="config.click_rate"
            v-cloak
        >
            <h6 class="card-title-sm">Click Through Rate</h6>
            <p class="card-value mb-0" v-if="cardStats.click_thru_rate" v-cloak>
                {{ cardStats.click_thru_rate.toFixed(2) }}%
                <span class="card-stat-change" v-if="cardStats.click_thru_rate_change" v-cloak>{{
                    cardStats.click_thru_rate_change
                }}</span>
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>-->

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The average commission percent"
            v-if="config.avg_percent"
            v-cloak
        >
            <h6 class="card-title-sm">MV AVG Commission Percent</h6>
            <p class="card-value mb-0" v-if="cardStats.avg_mv_percent" v-cloak>
                {{ cardStats.avg_mv_percent.toFixed(2) }}%
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_percent_change"
                    v-cloak
                    >{{ cardStats.total_percent_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The average commission percent"
            v-if="config.avg_affiliate_percent"
            v-cloak
        >
            <h6 class="card-title-sm">Affiliate AVG Commission Percent</h6>
            <p
                class="card-value mb-0"
                v-if="cardStats.avg_affiliate_percent"
                v-cloak
            >
                {{ cardStats.avg_affiliate_percent.toFixed(2) }}%
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_affiliate_percent_change"
                    v-cloak
                    >{{ cardStats.total_affiliate_percent_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="New Anonymous IDs"
            v-if="config.new_anon_ids"
            v-cloak
        >
            <h6 class="card-title-sm">New Anonymous IDs</h6>
            <p class="card-value mb-0" v-if="cardStats.new_anon_ids" v-cloak>
                {{ cardStats.new_anon_ids }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_new_anon_ids_percent_change"
                    v-cloak
                    >{{ cardStats.total_new_anon_ids_percent_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="AVG Anonymous IDs"
            v-if="config.avg_anon_ids"
            v-cloak
        >
            <h6 class="card-title-sm">AVG Anonymous IDs</h6>
            <p class="card-value mb-0" v-if="cardStats.avg_anon_ids" v-cloak>
                {{ cardStats.avg_anon_ids }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.avg_anon_ids_percent_change"
                    v-cloak
                    >{{ cardStats.avg_anon_ids_percent_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require("axios");
let currency = require("currency.js");

var appElement = document.getElementById("app");
var apiToken = appElement.getAttribute("data-api-token");

const HTTP = axios.create(
    (axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-TOKEN": app.csrfToken,
        Authorization: "Bearer " + apiToken,
        "Content-Type": "multipart/form-data",
    })
);

module.exports = {
    props: ["startDate", "endDate", "config"],
    data: function () {
        return {
            cardSmLoading: true,
            cardStats: {
                total_credit_card_revenue: 0,
                number_of_credit_card_sales: 0,
                mv_direct_sales: 0,
                affiliate_commission: 0.0,
                mv_commission: 0.0,
                rep_commission: 0.0,
                total_profit: 0.0,
                total_referrals: "00,000",
                total_card_click: "00,000",
                earnings_per_click: 0.0,
                click_thru_rate: 0.0,
                new_anon_ids: 0,
                avg_anon_ids: 0,
            },
        };
    },
    filters: {
        formatMoney(value) {
            if (!value) return "";
            return currency(value).format();
        },
        formatThousand(value) {
            if (!value) return "";
            return value.toLocaleString();
        },
    },
    methods: {
        getData() {
            this.cardSmLoading = true;
            HTTP.get(
                "/api/cardstats?start_date=" +
                    this.startDate +
                    "&end_date=" +
                    this.endDate
            )
                .then((response) => {
                    this.cardStats = response.data;
                    this.cardSmLoading = false;
                })
                .catch((e) => {
                    this.cardSmLoading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped></style>
