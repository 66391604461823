<template>
    <div class="dashboard-card has-inner top-cards">
        <div class="inner">
            <div class="dashboard-card-header">
                <div class="row">
                    <div class="col-md-7">
                        <h4 class="m-0">Top Selling Cards</h4>
                    </div>
                    <div class="col-md-2" v-if="showDateRange" v-cloak>
                        <div class="flatpickr">
                        <input type="date" name="start_date" v-model="sDate" data-callback="getData" placeholder="Start Date" class="form-control date-picker-component">
                        <i class="far fa-calendar"></i>
                        </div>
                    </div>
                    <div class="col-md-1 text-center pt-2" v-if="showDateRange" v-cloak>
                        &mdash;
                    </div>
                    <div class="col-md-2" v-if="showDateRange" v-cloak>
                        <div class="flatpickr">
                        <input type="date" name="end_date" v-model="eDate" data-callback="getData" placeholder="End Date" class="form-control date-picker-component">
                        <i class="far fa-calendar"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-card-body">
                <ul class="card-list">
                    <li v-for="card in cardItems" class="card-row">
                        <img class="card-card-image" :src="'https://cdn.creditcards.com/shared/images/cards/191x120/'+card.img_name" alt="Card image">
                        <span class="card-card-name" v-html="card.name"></span> <span class="card-card-values"><span class="card-card-total">{{ card.total | formatMoney }}</span>&nbsp;<span class="card-card-count">( {{ card.count }} )</span></span>
                    </li>
                </ul>
                <div class="card-loader" v-if="loading" v-cloak><i class="far fa-spinner-third fa-spin"></i></div>
            </div>
            <div class="dashboard-card-footer" v-if="showViewAll" v-cloak>
                <a type="button" class="btn btn-secondary btn-export" href="/card-sales" >View All</a>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
let currency = require('currency.js');

var appElement = document.getElementById('app');
var apiToken = appElement.getAttribute('data-api-token');

const HTTP = axios.create(axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : app.csrfToken,
    'Authorization' : 'Bearer '+ apiToken,
    'Content-Type': 'multipart/form-data'
});

module.exports = {
    props: [ 'startDate', 'endDate', 'showViewAll', 'showDateRange' ],
    data: function() {
        return {
            loading: true,
            length: 8,
            showAll: false,
            fromDashboard: false,
            sDate: null,
            eDate: null,
            cards: [],
        };
    },
    computed: {
        cardItems(){
            let length = !this.showViewAll? this.cards.length : this.length;
            return this.cards.slice(0, length);
        }
    },
    filters: {
        formatMoney(value){
            if (!value) return '';
            return currency(value).format();
        },
    },
    methods: {
        getData(){
            this.loading = true;
            let va = this.showViewAll? 'Y' : 'N';
            const sdate = this.fromDashboard? this.startDate : this.sDate;
            const edate = this.fromDashboard? this.endDate : this.eDate;

            const url = '/api/topsellingcards?start_date='+sdate+'&end_date='+edate+'&view_all='+va;

            HTTP.get(url)
            .then(response => {
                this.cards = response.data;
                this.loading = false;
            })
            .catch(e => {
                console.log('Top cards error', e);
                this.loading = false;
            });
        },
        toggleList: function(){
            this.showAll = !this.showAll;
        }
    },
    created(){

    },
    mounted(){
        let self = this;
        this.sDate = this.startDate;
        this.eDate = this.endDate;
        const datePickerComponent = flatpickr('.date-picker-component', { dateFormat: 'Y-m-d', onChange: function(selectedDates, dateStr, instance) {
            console.log(instance);
            const updateMethod = instance.element.getAttribute('data-callback');
            if( updateMethod.length ){

                if( instance.element.name === 'start_date' ){
                    Cookies.set('sdate', dateStr, { expires: 7 });
                }
                if( instance.element.name === 'end_date'){
                    Cookies.set('edate', dateStr, { expires: 7 });
                }

                setTimeout(()=>{
                    self[updateMethod]();
                }, 100)
            }
        }
        });

        this.getData();
    }
};
</script>

<style scoped>
.top-cards{
    margin-bottom: 32px;
}
.card-card-name{
    font-size: .875rem;
    padding: 0 6px;
    max-width: 300px;
}
.card-card-values{
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    min-width: 125px;
}
.card-card-total{
    font-weight: 600;
    font-size: .875rem;
}
.card-card-count{
    font-size: .75rem;
}
</style>
